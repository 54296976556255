<template>
  <b-overlay
    variant="white"
    :show="show"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
  >
    <b-card>
      <b-row>
        <b-col cols="12" md="8">
          <h4>Rol Yetki Yönetimi</h4>
        </b-col>
        <b-col cols="6" md="2" class="text-right"> </b-col>
        <b-col cols="6" md="2" class="text-right">
          <b-button
            v-b-modal.modal-prevent-closing
            variant="danger"
            block
            :disabled="selectedRole == null"
          >
            <feather-icon icon="PlusIcon" /> Yetki Ekle
          </b-button>
        </b-col>
      </b-row>
      <b-row class="pt-2">
        <b-col cols="12" md="2">
          <b-form-group label="Satır" label-for="name-input">
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="Role" label-for="name-input">
            <b-form-select
              v-model="selectedRole"
              size="sm"
              :options="roles"
              @change="getRolePerm(selectedRole)"
            /> </b-form-group
        ></b-col>
        <b-col cols="12" md="4">
          <b-form-group
            label-align-sm="left"
            label-for="name-input"
            class="mb-0"
            label="Ara"
          >
            <b-input-group size="sm">
              <b-form-input id="filterInput" v-model="filter" />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="pt-2">
        <b-col>
          <b-table
            :striped="false"
            :bordered="false"
            :hover="true"
            :busy="show"
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fields"
            :sticky-header="stickyHeader"
            responsive
            :filter="filter"
            class="custom-table"
          >
            <template #cell(actions)="data">
              <b-button
                @click="deletePerm(data.item)"
                size="sm"
                variant="danger"
              >
                <feather-icon icon="TrashIcon" size="16" />Delete
              </b-button>
            </template>
            <template #cell(createdAt)="data">
              {{ formatDate(data.item.createdAt) }}
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row class="pt-2">
        <b-col>
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            align="center"
            size="sm"
            class="my-0"
          />
        </b-col>
      </b-row>
    </b-card>
    <b-modal
      id="modal-prevent-closing"
      ref="my-modal"
      title="Modul Bilgilerini Giriniz"
      ok-title="Ekle"
      cancel-title="İptal"
      cancel-variant="outline-secondary"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
      size="lg"
      size-sm
    >
      <form ref="form" @submit.stop.prevent="addRole">
        <b-form-group
          :state="permState"
          label="Yetki"
          label-for="name-input"
          invalid-feedback="Yetki Boş Olamaz"
        >
          <b-form-select
            v-model="selectedPerm"
            :options="permissions"
            :state="permState"
            required
          />
        </b-form-group>
      </form>
    </b-modal>
  </b-overlay>
</template>
<script>
import {
  BCard,
  BCardText,
  BLink,
  BTable,
  BButton,
  BOverlay,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BModal,
  VBModal,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BTable,
    BButton,
    BOverlay,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BModal,
    ToastificationContent,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      stickyHeader: false,
      show: false,
      perPage: 8,
      pageOptions: [8, 10, 12],
      currentPage: 1,
      filter: null,

      roles: [],
      selectedRole: null,
      permState: null,
      selectedPerm: null,
      permissions: [],
      items: [],
      fields: [
        { key: "id", label: "No", sortable: true },
        { key: "permission.caption", label: "Acıklama", sortable: true },
        {
          key: "permission.permissionKey",
          label: "Modul Anahtarı",
          sortable: true,
        },
        {
          key: "createdAt",
          label: "Tarih",
          sortable: true,
        },
        { key: "actions", label: "Aksiyon", tdClass: "custom-width-actions" },
      ],
      boxOne: "",
      name: "",
      nameState: null,
      caption: "",
      captionState: null,
      icon: "",
      iconState: null,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    rows() {
      return this.items.length;
    },
  },
  created() {
    this.getRoles();
    this.getPerm();
  },
  methods: {
    formatDate(dateString) {
      const options = { year: "numeric", month: "long", day: "numeric" };
      const date = new Date(dateString);
      return date.toLocaleDateString("tr-TR", options);
    },
    async getRoles() {
      this.show = true;
      this.$http
        .get("role")
        .then(async (response) => {
          if (response.status == 200) {
            this.show = false;
            var satirlar = response.data;
            for (var i = 0; i < satirlar.length; i++) {
              var model = {
                value: satirlar[i].id,
                text: satirlar[i].caption,
              };
              this.roles.push(model);
            }
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hata`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.message,
            },
          });
        });
    },
    async getPerm() {
      var userData = JSON.parse(localStorage.getItem("HPUserData"));
      var roleId = userData.roleId;
      this.$http
        .get("perm/selectget?id=" + roleId)
        .then(async (response) => {
          if (response.status == 200) {
            var satirlar = response.data;
            for (var i = 0; i < satirlar.length; i++) {
              var model = {
                value: satirlar[i].id,
                text: satirlar[i].caption,
              };
              this.permissions.push(model);
            }
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hata`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.message,
            },
          });
        });
    },
    async getRolePerm(id) {
      this.$http
        .get("perm?id=" + id)
        .then(async (response) => {
          if (response.status == 200) {
            this.show = false;
            this.items = response.data;
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hata`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.message,
            },
          });
        });
    },
    async deletePerm(data) {
      this.boxOne = "";
      this.$bvModal
        .msgBoxConfirm("Yetkiyi Silmek İstediğinize Emin misiniz ?", {
          title: "Dikkat",
          size: "sm",
          okVariant: "primary",
          okTitle: "Evet",
          cancelTitle: "Hayır",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          this.boxOne = value;

          if (value == true) {
            this.show = true;
            this.$http
              .delete(`perm?id=${data.id}`)
              .then((response) => {
                if (response.status === 200) {
                  // If successful, update the table data by removing the deleted item
                  this.items = this.items.filter((i) => i.id !== data.id);
                  this.show = false;

                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: `Başarılı`,
                      icon: "CoffeeIcon",
                      variant: "success",
                      text: `Silme İşlemi Başarılı..`,
                    },
                  });
                }
              })
              .catch((error) => {
                this.show = false;
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Hata`,
                    icon: "CoffeeIcon",
                    variant: "danger",
                    text: error.response.data.message,
                  },
                });
              });
          }
        });
    },
    async addRolePerm() {
      if (!this.checkFormValidity()) {
        return;
      } else {
        var sendModel = {
          roleId: this.selectedRole,
          permissionId: this.selectedPerm,
        };
        this.$http
          .post("perm", sendModel)
          .then(async (response) => {
            if (response.status == 200) {
              await this.getRolePerm(sendModel.roleId);
              this.show = false;
              this.$bvModal.hide("modal-prevent-closing");
            }
          })
          .catch((error) => {
            console.log(error);
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Hata`,
                icon: "CoffeeIcon",
                variant: "danger",
                text: error.response.data.message,
              },
            });
          });
      }
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.permState = valid;
      return valid;
    },
    resetModal() {
      this.selectedPerm = null;
      this.permState = null;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.addRolePerm();
    },
  },
};
</script>

<style>
.custom-width-actions {
  width: 180px; /* Set the width of the Actions column */
}
</style>
